<template>
  <v-card style="height: 60%" class="mt-1">
    <v-card-title
      class="h-75px custom-card-title d-flex justify-content-between"
    >
      <div>
        <span class="font-weight-medium m-4 text-h5"
          >{{ $t("scanned_items") }}
        </span>
      </div>
      <div>
        <button
          v-if="scannedItems.length"
          class="btn btn-info btn-sm mr-3 px-5 py-3 ls1"
          @click="confirmOrderPicking"
        >
          Confirm order picking
        </button>
        <button
          v-if="selected.length"
          class="btn btn-danger btn-sm mr-3 px-5 py-3 ls1"
          @click="bulkDelete"
        >
          Delete
        </button>
      </div>
    </v-card-title>
    <v-card-text>
      <div class="scanned-block">
        <v-data-table
          :headers="headers"
          :items="scannedItems"
          show-select
          class="striped-table"
          item-value="id"
          v-model="selected"
        >
          <template v-slot:item="{ item, index, select, isSelected }">
            <tr :class="index % 2 === 0 ? 'even-row' : 'odd-row'">
              <td>
                <div
                  class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    @click="onSelect(item)"
                    :checked="isSelected"
                  />
                </div>
              </td>
              <td v-for="header in headers" :key="header.value">
                <template v-if="header.value === 'action'">
                  <span class="mt-2">
                    <v-icon left @click="removeItem(item)"> mdi-delete </v-icon>
                  </span>
                </template>
                <template v-else>{{ item[header.value] }}</template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SwalService from "@/core/services/swal.service";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_TABLE_DATA,
  SET_SCANNED_ITEMS,
  SET_SUGGESTED_DETAILS,
} from "@/core/services/store/standardOrderPicking.module";

export default {
  name: "ScannedCases",
  props: ["item", "toggleModal"],
  data() {
    return {
      headers: [
        // { text: "SKU", value: "sku_barcode" },
        // { text: "Scanned qty", value: "scanned_qty" },
        // { text: "Damaged", value: "is_damaged" },
        // { text: "UOM", value: "description" },
        // { text: "Batch no.", value: "batch_number" },
        // { text: "Serial no.", value: "serial_no" },
        // { text: "Action", value: "action" },
        // { text: "Id", value: "id" },
        // { text: "From location", value: "from_location" },
        // { text: "Package code", value: "from_package_code" },

        { text: "SKU", value: "sku_barcode" },
        { text: "Description", value: "description" },
        { text: "Damaged", value: "is_damaged" },
        { text: "Picked qty", value: "picked_qty" },
        { text: "Scanned qty", value: "scanned_qty" },
        { text: "Allocated qty", value: "allocated_qty" },
        { text: "Pending qty", value: "pending_qty" },
        { text: "Batch no.", value: "batch_number" },
        { text: "Serial no.", value: "serial_no" },
        { text: "Action", value: "action" },
      ],
      selected: [],
    };
  },
  methods: {
    removeItem(item, isBulk = false) {
      SwalService.warningConditionMessage(
        {
          title: `Are you sure?`,
          html: `Do you want to remove ${
            isBulk ? "selected items" : item.sku_barcode
          }`,
          confirmButtonText: `Yes`,
        },
        () => {
          this.$store.commit(SET_PAGE_LOADING, true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            id: isBulk ? item[0].order_id : item.order_id,
            items: isBulk ? item.map((item) => item.id) : [item.id],
          };

          ApiService.post(
            "warehouse_management/custom/standard_order_picking/remove-scanned-items",
            payload
          )
            .then((response) => {
              SwalService.successMessage({
                title: this.$t("success"),
                html: this.$t("record_has_been_deleted_successfully"),
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.$store.commit(SET_SCANNED_ITEMS, {
                operation: null,
                data: response.data.scanned_items,
              });
              this.$store.commit(SET_SUGGESTED_DETAILS, {
                operation: "spread",
                info: {
                  ...response.data.suggested_task,
                },
              });
              this.selected = [];
            })
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        }
      );
    },
    bulkDelete() {
      this.removeItem(this.selected, true);
    },
    confirmOrderPicking() {
      SwalService.warningConditionMessage(
        {
          title: `Are you sure?`,
          html: `Do you want to confirm order picking`,
          confirmButtonText: `Yes`,
        },
        () => {
          this.$store.commit(SET_PAGE_LOADING, true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            id: this.item.id,
          };

          ApiService.post(
            "warehouse_management/custom/standard_order_picking/update",
            payload
          )
            .then(() => {
              SwalService.successMessage({
                title: this.$t("success"),
                html: this.$t(this.$t("updated_successfully")),
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.toggleModal();
              this.$store.dispatch(UPDATE_TABLE_DATA, {
                warehouse_id: this.selectedWarehouse,
              });
            })
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        }
      );
    },
    onSelect(item) {
      const index = this.selected.findIndex((i) => i.id === item.id);
      if (index === -1) {
        this.selected.push(item);
      } else {
        const selectedCopy = this.selected;
        selectedCopy.splice(index, 1);
        this.selected = [...selectedCopy];
      }
    },
  },
  computed: {
    scannedItems: function() {
      return this.$store.getters.getSTANDARDORDERPICKINGScannedItems;
    },
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
};
</script>

<style scoped>
.striped-table {
  width: 100%;
  border-collapse: collapse;
}

.striped-table tr {
  border-bottom: 1px solid #e0e0e0;
}

.striped-table .even-row {
  background-color: #f9f9f9;
}

.striped-table .odd-row {
  background-color: #ffffff;
}

.scanned-block {
  height: calc(90vh - 462px);
  overflow: auto;
}
</style>
